<template>
  <div
    class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
    <template v-if="search.length">
      <div class="tasks-list__no-results">
        No search results found
      </div>
    </template>
    <template v-else>
      <CIcon class="table__clear__icon" size="6xl" name="cilTask" />
      <div class="table__clear__title">No tasks yet</div>
      <div class="table__clear__title-sub">
        All tasks you create will be displayed here
      </div>
      <CButton
        color="primary"
        class="table__clear__btn"
        @click="handleCreateTask">
          Create a task
      </CButton
      >
    </template>
  </div>
</template>

<script>
import { notifyByPlan } from "@/services/Notify/Toasts";
import Gate from '@/services/Gate/Gate'

export default {
  name: "NoTasks",
  props: {
    search: { type: Array, default: () => [] },
    redirect: { type: Boolean, default: false }
  },
  data() {
    return {
      projects: null,
      projectId: null
    }
  },
  methods: {
    handleCreateTask() {
      if (this.redirect) {
        this.$emit('onCreate');
        return
      }

      this.goTaskCreate()
    },
    goTaskCreate() {
      Gate.can('taskCreate', 'project', this.projectId).then((res) => {
        if (res) {
          return this.$router.push({
            name: 'ProjectTasksCreate',
            params: { id: this.projectId },
          })
        }

        notifyByPlan()
      })
    }
  },
  created() {
    this.$http.projects
      .fetchProjects({
        params: {
          per_page: -1,
          page: 1,
          search: '',
          status: ['active'],
          sort_field: '',
          sort_direction: 'asc',
        },
      })
      .then(({ data: { data } }) => {
        this.projects = data.map((item) => ({
          value: item.id,
          label: item.name,
          info: item,
        }))
        let myProjects = this.projects.filter((el) => el.info.company_id === this.$store.getters.user?.company?.id)
        let defaultProject = myProjects[0]?.value || false

        let projectId = (!this.ignoreActiveProjectId && this.$route.params?.id)
        this.projectId = myProjects.find((el) => el.value === parseInt(projectId))?.value || defaultProject
      })
  }
};
</script>

<style lang="scss" scoped>
.table__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 60px;
  background: #f5f7f9;

  &__icon {
    color: #b1b7c1;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #303c54;

    &-sub {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 14px;
      color: #465967;
    }
  }
}
</style>
